exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-let-us-chill-2024-js": () => import("./../../../src/pages/let-us-chill-2024.js" /* webpackChunkName: "component---src-pages-let-us-chill-2024-js" */),
  "component---src-pages-letter-to-mib-let-us-chill-js": () => import("./../../../src/pages/letter-to-MIB-let-us-chill.js" /* webpackChunkName: "component---src-pages-letter-to-mib-let-us-chill-js" */),
  "component---src-pages-letter-to-trai-to-protect-net-neutrality-again-js": () => import("./../../../src/pages/letter-to-trai-to-protect-net-neutrality-again.js" /* webpackChunkName: "component---src-pages-letter-to-trai-to-protect-net-neutrality-again-js" */),
  "component---src-pages-p-[uuid]-js": () => import("./../../../src/pages/p/[uuid].js" /* webpackChunkName: "component---src-pages-p-[uuid]-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

